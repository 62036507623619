/*********** GENERAL OVERRIDE **********/

html {
	background: none !important;
}

div {
	&#primary_nav, &#promo, &#profile_lite div.extra {
		background: none !important;
	}
}

html *, table, th, td {
	border: none !important;
}

div {
	&#header {
		box-shadow: none !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		filter: none !important;
		-ms-filter: none !important;
	}

	&#profile_lite {
		background: none repeat scroll 0 0 #ffffff !important;
		border: 0 none !important;
		color: #000000 !important;
		box-shadow: none !important;
		-moz-box-shadow: none !important;
		-webkit-box-shadow: none !important;
		margin-top: 6px !important;
		overflow: hidden !important;
		text-overflow: ellipsis !important;
		white-space: nowrap !important;
		filter: none !important;
		-ms-filter: none !important;

		h3, em, div.extra {
			margin-top: -1px !important;
		}
	}

	&#promo {
		border: 1px solid #cfcfcf !important;
	}

	&#primary_widget_outer {
		float: none !important;
	}

	&#profile_lite {
		h3, em, div.extra {
			margin-top: 5px !important;
			color: #000000 !important;
		}
	}

	&#main {
		width: 900px !important;
		margin-top: 0 !important;
	}

	&#primary_widget_outer {
		box-shadow: none !important;
		-moz-box-shadow: none !important;
		-webkit-box-shadow: none !important;
		border: 0 none !important;
	}

	&#profile_lite {
		h3, em, div.extra, a, div.extra a {
			color: #000000 !important;
			text-shadow: none !important;
		}
	}
}

/*********************************/
/************ DASHBOARD **********/
/*********************************/

/****** stcu header *******/

#primary_nav {
	display: none !important;
}

div#footer_nav .left li {
	display: inline-block !important;
}

.button {
	border: 1px solid #bebfbf !important;
}

#utility_nav a {
	background: #fff !important;
}

div#utility_nav {
	margin-right: 115px !important;
}

#global_search {
	display: none;
}

div#header {
	border-top: 0 none !important;
}

a#button_profile img {
	border: 0 none !important;
}

div {
	&#utility_nav a {
		color: #636363 !important;
		text-shadow: none !important;

		&.button span.badge {
			background: #e4e4e4 !important;
			color: #636363 !important;
			text-shadow: none !important;
		}

		&#hdr_button_messages.button:before {
			bottom: 1px !important;
		}
	}

	&#primary_widget_title {
		background-color: #ffffff !important;
		margin: 0 !important;
	}
}

/****** end stcu *******/

h1#content_header, div#primary_widget_title h1 {
	color: #545554 !important;
}

.mod {
	background: none repeat scroll 0 0 #E3E3E3 !important;
	border: 1px solid #d5d5d5 !important;

	h4 {
		color: #333333 !important;
		text-shadow: none !important;
	}

	&.dash-mod div.links {
		display: none !important;
	}
}

#related_content, div#help_articles {
	display: none !important;
}

/*********************************/
/************ ACCOUNTS **********/
/*********************************/

.title-bar, #filters, .filters, .list-header, table .title-bar td {
	background: #fff !important;
	border: 1px solid #c3c3c3 !important;
	text-shadow: none !important;
	color: #333333 !important;
}

.title-bar span:first-child {
	color: #333333 !important;

	a {
		color: #333333 !important;
	}
}

.filters {
	color: #333333 !important;
}

table.data {
	thead tr, tbody tr {
		border: 1px solid #d1d2d4 !important;
	}
}

a {
	&.action, &.button {
		background: none !important;
	}
}

input.button, span.button, .list-header {
	background: none !important;
}

table.button button {
	background: none !important;
}

input[type="submit"], .title-bar, #filters, .filters {
	background: none !important;
}

ul#progress_nav li {
	span.line, .step {
		background: none !important;
	}
}

a {
	&.cancel.button, &.secondary.button {
		background: none !important;
	}
}

input {
	&.cancel.button, &.secondary.button {
		background: none !important;
	}
}

table.button.secondary button, .delete-category span, .ui-progressbar-value, .list-header.selected, .list-item.selected, .selected, .ui-state-active .button {
	background: none !important;
}

.list-item {
	&.selected:hover, &.x-combo-selected {
		background: none !important;
	}
}

table.ui-datepicker-calendar tr td.ui-datepicker-current-day a {
	background: none !important;
}

ul#progress_nav li.ui-state-active {
	span.line, .step {
		background: none !important;
	}
}

#modal_sidenav ul li a.current, table tr.title-bar td {
	background: none !important;
}

.title-bar {
	background-color: #c3c3c3 !important;
	border-bottom: 1px solid #d1d2d4 !important;
	color: #333333 !important;
}

#filters, .filters, .list-header, table .title-bar td {
	color: #333333 !important;
}

.overview table.data th {
	text-shadow: none !important;
}

/*********************************/
/*********** ACCOUNTSV2 **********/
/*********************************/

/* Main page overrides */

#accounts_list, #primary_widget.selection-view #column_wrapper:before {
	display: none !important;
}

#info_panel_header {
	.tabs, .account-info:before {
		display: none !important;
	}
}

#widget_nav, #footer_nav, a.load-more, #promo, #profile_lite, #widget_actions, .grid-actions, #search_sort {
	display: none !important;
}

#primary_widget_outer .selection-view {
	#primary_widget_content:before, .main #info_panel_header {
		background: none !important;
		background-color: transparent !important;
	}
}

#primary_widget #primary_widget_title {
	background: none !important;
	background-color: transparent !important;
}

.tab-container, #primary_widget_title {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

#primary_widget #primary_widget_title {
	left: inherit !important;
	margin-bottom: 0 !important;
	padding-bottom: 0 !important;
	padding-top: 30px !important;

	.print-title {
		display: block !important;
	}

	h2 {
		padding-bottom: 11px !important;
	}
}

/* Fixed header and logo */

.accounts #header, #header_container {
	height: auto !important;
}

#header h1 {
	height: auto !important;
	margin-top: 5px;
	margin-left: 1px;
	padding: 10px;
	width: auto;
}

div#header:after {
	content: " ";
	display: block;
	clear: both;
	height: 0;
}

body div#main {
	margin-top: 0 !important;
}

/* Accounts header */

#info_panel_header {
	border-top: 1px solid #d5d5d5 !important;
	color: #000000 !important;
	padding: 35px 0 10px 0 !important;
}

.account-info {
	padding: 0 !important;
	color: #000000 !important;
}

.accounts #main, #header_container {
	margin-left: 0 !important;
	margin-right: 0 !important;
}

#info_panel_header * {
	color: #000000 !important;
}

.header-info {
	margin-bottom: 25px !important;

	.meta {
		padding-left: 15px !important;
	}
}

#primary_widget_outer, .accounts #main, .main {
	border-radius: 0 !important;
	width: 100% !important;
}

.selection-view .main {
	margin-left: 0 !important;
}

/* Transactions Listing */

table.transactions {
	col {
		width: 15% !important;

		&.date {
			width: 6% !important;
		}
	}

	td.date span.year {
		margin-top: 0 !important;
	}

	th {
		font-size: 12px !important;
	}

	td {
		&.balance {
			line-height: normal !important;
		}

		&.details span.description {
			margin-bottom: 0 !important;
		}
	}
}

a.trans-category {
	padding: 5px 0 0 0 !important;

	&:after {
		display: none;
	}
}

table.transactions tr {
	border-top: 1px solid #d5d5d5 !important;
	border-bottom: 1px solid #d5d5d5 !important;
}

tr.transaction-row {
	height: 80px !important;
	max-height: 80px !important;
	page-break-inside: avoid !important;
}

.list {
	border-top: 1px solid #eeeeee !important;
	margin-right: 0 !important;

	dd {
		border-bottom: 1px solid #eeeeee !important;
	}
}

div#footer ul#notices li {
	box-sizing: border-box;
	padding-right: 0 !important;
	width: 50%;
}

/*********************************/
/********** EXTRA CONTENT ********/
/*********************************/

/* Transaction count, statement date, under page title. */

.print-header {
	display: block !important;
	padding: 10px;
	border: 1px solid #d5d5d5 !important;
}

.print-header-date {
	float: right;
}

.statement-period, .statement-date {
	padding-left: 25px;
}

/* What print filters are active. */

.print-filters {
	display: block !important;
	margin-top: 25px;
	margin-bottom: 10px;
}

.filters-header {
	display: block;
	font-weight: 700;
	padding-bottom: 5px;
}

.filters-box {
	border: 1px solid #d5d5d5 !important;
	display: inline-block;
	margin-bottom: 15px;
	margin-right: 15px;
}

.filters-box-left {
	border-right: 1px solid #d5d5d5 !important;
	display: inline-block;
	font-weight: 700;
	padding: 8px;
}

.filters-box-right {
	display: inline-block;
	padding: 8px;
}

/*********************************/
/********** AMORTIZATION *********/
/*********************************/

.selection-view .tab-container {
	padding-top: 0 !important;
}

.amortization-page-header {
	display: none !important;
}

.amortization-header {
	border-top: 1px solid #d5d5d5 !important;
	border-bottom: 1px solid #d5d5d5 !important;
	padding: 5px 10px 1px !important;
}

.amortization-header-title .date {
	font-size: 12px !important;
}

.amortization-row {
	border-bottom: 1px solid #d5d5d5 !important;
}

.amortization-row-amount:first-child {
	padding-right: 22%;
}

/********* MISC *********/

div#footer {
	color: #414141 !important;

	a {
		color: #414141 !important;
	}
}